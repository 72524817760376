<template>
  <v-app>
    <v-main style="background-color: #639cd9">
      <v-layout fill-height fluid>
        <v-row align="center" justify="center">
          <v-col v-if="showContact" align="center">
            <Contact/>
          </v-col>
          <v-col v-if="!showContact" align="center">
            <h1 v-if="!$vuetify.breakpoint.xsOnly" class="white--text mb-2" style="font-size: 70px; font-family: Montserrat">IT Support Basel</h1>
            <h1 v-if="$vuetify.breakpoint.xsOnly" class="white--text mb-2" style="font-size: 40px; font-family: Montserrat">IT Support Basel</h1>
            <Card v-if="$vuetify.breakpoint.lgAndUp"/>
            <CardMiddle v-if="!$vuetify.breakpoint.xsOnly && !$vuetify.breakpoint.lgAndUp"/>
            <CardMobile v-if="$vuetify.breakpoint.xsOnly"/>
            <h1 style="font-family: Montserrat" class="white--text mt-2">CHF 60.- statt CHF 80.-</h1>
            <v-btn @click="buyAction" color="#f7c469" class="mt-2 mb-5" x-large>Gutschein kaufen</v-btn>
          </v-col>
        </v-row>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
import Card from './components/Card';
import CardMobile from "@/components/CardMobile";
import CardMiddle from "@/components/CardMiddle";
import Contact from "@/components/Contact";

export default {
  name: 'App',

  components: {
    Contact,
    CardMiddle,
    CardMobile,
    Card,
  },

  data: () => ({
    showContact: false,
    quantity: 0
  }),
  methods: {
    buyAction () {
      this.showContact = true;
    },

  }
};
</script>
