<template>
  <v-col align="center" sm="12">
    <v-card :height="this.$vuetify.breakpoint.height * 0.75" :width="this.$vuetify.breakpoint.width * 0.8" class="rounded-lg" elevation="5">
      <v-card-title>
        <v-spacer/>
        <h2 class="mt-2" style="font-family: Montserrat">GUTSCHEIN</h2>
        <v-spacer/>
      </v-card-title>
      <v-row>
        <v-col align="center" class="pa-5">
          <v-img class="mt-2" src="IT-Support.png" max-width="40%"></v-img>
          <h1 class="red--text" style="font-family: Montserrat; font-size: 30px">25% Rabatt</h1>
          <v-divider class="mt-2 mb-2"/>
          <h2 style="font-family: Montserrat">1 Stunde PC-SUPPORT</h2>
          <v-divider class="mt-2 mb-2"/>
          <h4 style="font-family: Montserrat; font-weight: normal">Gültig ab 1.3.2022</h4>
          <v-divider class="mt-2 mb-2"/>
          <h5 style="font-family: Montserrat">Damit sind Sie nicht alleine - immer mehr Menschen wünschen sich Unterstützung im Umgang mit Technik und neuen Medien.
            Mit viel Geduld und Verständnis ermögliche ich einen Anschluss an die Digitalisierung für alle Altersgruppen.
            Rufen Sie mich an oder schreiben Sie mir eine Mail für eine Terminvereinbarung.
            <br><br>
            Ihr digitaler Begleiter <br>
            Alejandro Garcia</h5>
          <v-spacer/>
          <v-divider class="mt-2 mb-2"/>
        </v-col>
      </v-row>
<!--      <v-card-actions>-->
<!--        <v-row justify="center">-->
<!--            <p class="caption">IT Support Basel </p>-->
<!--            <p class="caption ml-2 mr-2"> | Spalenring 77, 4055 Basel | </p>-->
<!--            <p class="caption mr-2">mail@it-support-basel.ch | </p>-->
<!--            <p class="caption">www.it-support-basel.ch</p>-->
<!--        </v-row>-->
<!--      </v-card-actions>-->
    </v-card>
  </v-col>
</template>

<script>
export default {
name: "CardMobile"
}
</script>

<style scoped>

</style>
