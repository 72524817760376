<template>
  <div class="container">
    <form @submit="sendEmail">
      <label>Anrede</label>
      <input
          type="text"
          v-model="anrede"
          name="anrede"
          placeholder="Anrede"
      >
      <label>Name</label>
      <input
          type="text"
          v-model="nachname"
          name="nachname"
          placeholder="Ihr Nachname"
      >
      <label>Email</label>
      <input
          type="email"
          v-model="email"
          name="email"
          placeholder="Ihre Email"
      >
      <label>Adresse</label>
      <textarea
          name="adresse"
          v-model="adresse"
          cols="30" rows="3"
          placeholder="Adresse">
          </textarea>
      <label>Anzahl Gutscheinen</label>
      <input
          type="text"
          name="amount"
          min="1"
          max="20"
          v-model="amount"
          placeholder="Anzahl"
      >
      <input type="submit" value="Kaufen"/>
    </form>
    <v-snackbar
        timeout="2000"
        color="green"
        v-model="snackbarSuccess"
    >
      Kauf erfolgreich!
    </v-snackbar>
    <v-snackbar
        timeout="2000"
        color="red"
        v-model="snackbarError"
    >
      Kauf nicht erfolgreich!
    </v-snackbar>
  </div>
</template>

<script>
import emailjs from 'emailjs-com';
export default {
  name: "Contact",
  data() {
    return {
      snackbarSuccess: false,
      snackbarError: false,
      anrede: '',
      nachname: '',
      email: '',
      adresse: '',
      amount: '1'
    }
  },
  methods: {
    sendEmail(e) {
      console.log('function called')
        try {
          console.log('in try')
          emailjs.sendForm('service_nlu31m5', 'template_7j0z2kb', e.target,
              'user_lJKf4hh8geTUdsW6XjWj4', {
                anrede: this.anrede,
                nachname: this.nachname,
                email: this.email,
                adresse: this.adresse,
                amount: this.amount
              });

        } catch(error) {
          console.log('error')
          console.log({error})
        }
        // Reset form field
        this.anrede = ''
        this.name = ''
        this.email = ''
        this.adresse = ''
        this.amount = '1'
        //this.snackbarSuccess = true;

    },
  },
}
</script>

<style scoped>
* {box-sizing: border-box;}

.container {
  display: block;
  margin:auto;
  text-align: center;
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
  width: 50%;
}

label {
  float: left;
}

input[type=text], [type=email], textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type=submit] {
  background-color: #4CAF50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color: #45a049;
}
</style>
